import ValidationErrorCollection from './ValidationErrorCollection';

function validate(command, settings) {
  let messages = new ValidationErrorCollection();

  if (!command) {
    throw new Error('command cannot be null'); 
  }

  required('country', 'Please select your country');

  return messages;
  
  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};
