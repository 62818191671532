import React from 'react';
import loggerService from 'services/loggerService';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            is500: (props.is500 ? props.is500 : false)
        };
    }

    componentDidCatch(error, info) {
        loggerService.logError(error, info);

        this.setState({
            hasError: true
        });
    }

    render() {
      if (this.state.hasError || this.state.is500) {
        return (
          <>
           <Header enableMenu={false} is500={true} />
           <main>
          <section className="layout-general layout-wrapper">
            <div className="layout-container layout-container--wide layout-container--center ">
              <div className='form__intro'>
                <h1 className="type-h1">Oops...</h1>
                <p>We're experiencing a technical difficulty,<br/> please come back later.</p>
              </div>
            </div>
          </section>
          </main>
          <Footer />
          </>
        )
      }
  
      return this.props.children;
    }
  }
  
  export default ErrorBoundary;