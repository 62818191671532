import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import timer from 'utils/timer';
import otpService from 'services/otpService';
import useSettingsContext from 'contexts/useSettingsContext';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import Link from 'components/Link/Link';

import OTPConfirmationForm from 'containers/Forms/OTPConfirmationForm';
import AlertIcon from 'assets/images/alert.png';
import './OTP.scss';
import Registration from 'containers/Forms/Registration';
import OTPEntryVerificationForm from 'containers/Forms/OTPEntryVerificationForm';
import analyticsService from 'services/analyticsService';

import {getOtpErrorMessages} from 'resources/otpErrorMessages';



export default (props) => {
    let settings = useSettingsContext();

    //let { token } = props.token;
    let [country, setCountry] = useState(null);
    const MAX_ATTEMPTS = settings.otpMaxEntryTries;
    let [otpStatus, setOtpStatus] = useState("trigger-otp");
    let [modalStatus, setModalStatus] = useState("");
    let [modalStatusRetry, setModalStatusRetry] = useState(true);
    

    let [flowId, setFlowId] = useState("");
    let [cxId, setCxId] = useState("");
    let [inputOtp, setInputOtp] = useState("");
    let [inputVal, setInputVal] = useState("");
    let [inputEmail, setInputEmail] = useState("");
    let [entryOtp, setEntryOtp] = useState("");
    let [flowToken, setFlowToken] = useState("");
    let [disableOTPFields, setDisableOTPFields] = useState(true);
    let [clearData, setClearData] = useState(false);
    let [clearCaptcha, setClearCaptcha] = useState(false);
    
    let [competitionEntryId, setCompetitionEntryId] = useState(""); 
    let [otpValidationError, setOtpValidationError] = useState(""); 
    let [currNumAttp, setCurrNumAttp] = useState(0);


    let [showOtpResend, setShowOtpResend] = useState(true);
    let [otpResendCouter, setOtpResendCouter] = useState({minutes: "02", seconds: "00"});
  
    function setInvalidState(){
        setOtpValidationError("Something went wrong.");
        clearModalStatus(""); 
        setOtpStatus("invalid");
    }

    
    const runCountDownTimer = () => {
        const TWO_MINUTES = 2 * 60 * 1000;
        const NOW_IN_MS = new Date().getTime();
        const TARGET_TIME = NOW_IN_MS + TWO_MINUTES;
        setShowOtpResend(false);

        var x = setInterval(function() {
            const now = new Date().getTime();
            var distance = TARGET_TIME - now;

            // Time calculations for days, hours, minutes and seconds
            const minutes = Math.floor(distance / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);
          
            // Display the result in the element with id="demo"
            var counter = {minutes: "0"+minutes, seconds: seconds > 10 ? seconds : "0"+seconds};
            setOtpResendCouter(counter);
          
            // If the count down is finished, write some text
            if (distance < 0) {
              clearInterval(x);
              setShowOtpResend(true);
              setOtpResendCouter({minutes: "02", seconds: "00"});
            }
        }, 1000);
    }

    
    async function handleOtpResendCode (inputVal, flowToken) {
        const data = {inputVal: inputVal, entryFlowToken: flowToken};

        analyticsService.logEvent("link_click", "external_link", {
            location: "otp_entry_verification_form",
            label: "resend otp code link"
        }); 

        await otpService.resendOTP(data).then((result) => {

            if (result.isValid && result.cxId ) {                
                setFlowToken(result.flowToken);
                setCxId(result.cxId);
            
                clearModalStatus("Valid"); 
                timer.delay(1500);
                clearModalStatus("");
                setOtpStatus("confirm-otp");

                setDisableOTPFields(false);

                runCountDownTimer();
            }
            else if (!result.isValid) {                   
                if (result.error && result.error.code) 
                {                
                    var currErrorCode = getOtpErrorMessages(result.error.code);                    
                    if(currErrorCode !== undefined && currErrorCode !== null) {
                        clearModalStatus("NotFound");  
                        setOtpValidationError(currErrorCode.message); 
                        setModalStatusRetry(currErrorCode.retry);

                    }  else {
                        setInvalidState();
                        setOtpValidationError(result.error.message);
                    }
                } else {
                    setInvalidState();
                }                             
            } 
            else if(!result.isValid && result.error === null) {                 
                setInvalidState();
            } 
        })
        
        .catch((err) => {  
            setInvalidState();
        });
    }

    async function onTriggerOTPSubmit(data) {
        setModalStatus("Uploading");
        setDisableOTPFields(true);
        setClearData(false);
        await timer.delay(1000);
        
        await otpService.requestOTP(data).then((currOtpData) => {

            if (currOtpData.isValid && currOtpData.cxId ) {                
                setFlowToken(currOtpData.flowToken);
                setCxId(currOtpData.cxId);
                setInputOtp(data.otp);
                setInputVal(data.inputPhoneNumber);
                setInputEmail(data.inputEmail);
              
                clearModalStatus("Valid"); 
                timer.delay(1500);
                clearModalStatus("");
                setOtpStatus("confirm-otp");
                setDisableOTPFields(false);

            }
             
            else if (!currOtpData.isValid) {                   
                if (currOtpData.error && currOtpData.error.code) 
                {      
                    var currErrorCode = getOtpErrorMessages(currOtpData.error.code);                    
                    if(currErrorCode !== undefined && currErrorCode !== null) {
                        clearModalStatus("NotFound");  
                        setOtpValidationError(currErrorCode.message); 
                        setModalStatusRetry(currErrorCode.retry);
                        
                    }  else {
                        setInvalidState();
                        setOtpValidationError(currOtpData.error.message);
                    }
                } else {
                    setInvalidState();
                }                             
            } 
            else if(!currOtpData.isValid && currOtpData.error === null) {                 
                setInvalidState();
            } 
        })
      
        .catch((err) => {  
            setInvalidState();
        });

    }



    async function onConfirmOTPSubmit(data) {
        setModalStatus("Uploading");
        setDisableOTPFields(true);
        setClearData(false);
        data.entryFlowToken = flowToken;
        await timer.delay(1000);

        await otpService.validateOTP(data).then((currOtpData) => {    

            if (currOtpData.isValid && currOtpData.numTries < MAX_ATTEMPTS ) {
                setFlowToken(currOtpData.entryFlowToken);                
                setEntryOtp(data.otp);                
                clearModalStatus("Valid"); 
                timer.delay(1500);
                clearModalStatus("");
                setOtpStatus("registration-form");

            }
            else if (!currOtpData.isValid && currOtpData.numTries < MAX_ATTEMPTS) {                
                if (currOtpData.error && currOtpData.error.errorCode) 
                {                    
                    var currErrorCode = getOtpErrorMessages(currOtpData.error.errorCode);
                    
                    if(currErrorCode !== undefined && currErrorCode !== null) {
                        setCurrNumAttp(currOtpData.numTries);
                        clearModalStatus("NotFound");  
                        setOtpValidationError(currErrorCode.message);   
                    } else {
                        setInvalidState();
                        setOtpValidationError(currErrorCode.message);   
                    }    
                } else {
                    setInvalidState();
                    setOtpValidationError(currOtpData.error.message);
                }        
                
                
            } else if (!currOtpData.isValid && currOtpData.numTries >= MAX_ATTEMPTS) {
                clearModalStatus(""); 
                setOtpStatus('TooManyAttempts');
            } 


            else if(!currOtpData.isValid && currOtpData.error === null) {                
                setInvalidState();
            } 
        })
      
        .catch((err) => {   
            setInvalidState();
        });

    }


    function onDismissModal () {
        setDisableOTPFields(false);
        setClearData(true);
        setClearCaptcha(true);

        timer.delay(500);
        clearModalStatus("");
    }

    function clearModalStatus (status) {
        setTimeout(function(){
            document.body.classList.remove("overflow-hidden-mediumdown"); 
        }, 500);
        setModalStatus(status);  
    }

    function renderStatus(otpStatus) {

        switch (otpStatus) {
            case 'trigger-otp': return (
                <>
                
                <OTPEntryVerificationForm 
                    onSubmit={onTriggerOTPSubmit}
                    actionId="entry"
                    clearCaptcha={clearCaptcha}                 
                />
                
                {
                    modalStatus === "Uploading" &&
                    <Modal loading />
                }
                {
                    modalStatus === "Valid" &&
                    <Modal success />
                }
                {
                    modalStatus === "NotFound" &&
                    <Modal 
                        panel 
                        error
                        icon={AlertIcon} 
                        alt="Warning sign"
                        onConfirm={onDismissModal}
                        title="Mmmm - that hasn't worked…">                      
                        {
                            otpValidationError && 
                            <p>{otpValidationError}</p>
                        }
                        
                        {
                            modalStatusRetry ? (
                                <Button title="Try again" onClick={onDismissModal} />
                            ) : (
                                <Button title="Back to homepage" href="/" />
                            )
                        }
                        
                    </Modal>
                }  
                </>
                
            )
        
            case "confirm-otp": 
            return (<>
                <Helmet>
                <meta charSet="utf-8" />
                <title>Entry Verification| Cadbury - Win a Day to Remember</title>
                </Helmet>
                <section className="layout layout-general layout-wrapper">
                    <div className="layout-container layout-container--slim layout-container--center otp-validation">
                        
                        <h1 className="type-h1">Now enter your one-time passcode</h1> 
                        <div className='form__intro' >
                            <p>This would have just been sent to the mobile number you registered with.</p>
                        </div>
                        
                        <OTPConfirmationForm
                            onSubmit={onConfirmOTPSubmit} 
                            actionId="entry"
                            cxId={cxId}
                            inputVal={inputVal}
                            email={inputEmail}
                            competitionEntryId={competitionEntryId}
                            disableOTPFields={disableOTPFields}
                            clearData={clearData}
                            country={country}                        
                            otpLength={6}
                        />
                    
                        <div className='otp-validation__resend'>
                            {
                                showOtpResend ? (
                                    <Button 
                                        title="Resend code"
                                        className="button--text-link"
                                        onClick={e => handleOtpResendCode(inputVal, flowToken)} 
                                    />
                                ) : ( <p>Resend code in <b>{otpResendCouter.minutes}:{otpResendCouter.seconds}</b></p>)
                            }
                        </div>
                    </div>
                </section>

                {
                    modalStatus === "Uploading" &&
                    <Modal loading />
                }
                {
                    modalStatus === "Valid" &&
                    <Modal success />
                }
                {
                    modalStatus === "NotFound" &&
                    <Modal 
                        panel 
                        error
                        icon={AlertIcon} 
                        alt="Warning sign"
                        onConfirm={onDismissModal}
                        title="Mmmm - that hasn't worked…">                      
                        {
                            otpValidationError && 
                            <p>{otpValidationError}</p>
                        }
                        {
                            (currNumAttp === (MAX_ATTEMPTS - 2) || currNumAttp === (MAX_ATTEMPTS - 1)) &&
                            <p>
                                Be aware that you only have {(MAX_ATTEMPTS - currNumAttp)} remaining {(MAX_ATTEMPTS - currNumAttp) === 1 ? "attempt" : "attempts"}.
                            </p>
                        }
                        
                        <Button title="Try again" onClick={onDismissModal} />
                    </Modal>
                }   
                    
                
            </>)
            case 'registration-form': return (
                <Registration 
                    otpAvilable={true}
                    token={""}
                    otp={inputOtp}
                    flowId={flowId}
                    flowToken={flowToken}
                    email={inputEmail}
                    phoneNumber={inputVal}
                />
            )
            case 'TooManyAttempts': return (
                <>
                <Helmet>
                <meta charSet="utf-8" />
                <title>Error - Entry Verification | Cadbury - Win a Day to Remember</title>
                </Helmet>
                <section className="layout layout-general layout-wrapper">
                    <div className="layout-container layout-container--slim layout-container--center otp-validation">
                        
                        <img src={AlertIcon} alt="Warning sign" className='otp-validation__icon'/>
                        <h1 className="type-h3">OH,  we're sorry but you've entered the wrong details too many times.</h1> 
                        <div className='otp-validation__ctas'>
                            <Link title="FAQs" href="/faqs" 
                            analytics={{evnt: "button_click", category: "internal_link", location: "otp_entry_verification_form - too many attempts", label: "faqs page"}}
                            lozenge route>FAQs</Link>
                        </div>
                    </div>
                </section>
                </>
            )       
            case 'invalid': return (
                <>
                <Helmet>
                <meta charSet="utf-8" />
                <title>Sorry - Entry Verification | Cadbury - Win a Day to Remember</title>
                </Helmet>
                <section className="layout layout-general layout-wrapper">
                    <div className="layout-container layout-container--slim layout-container--center otp-validation">
                        
                        <h1 className="type-h3">Sorry!</h1> 
                        <div className="form__intro">
                            {
                                otpValidationError && 
                                <p>{otpValidationError}</p>
                            }
                        </div>
                        <div className='otp-validation__ctas'>
                            <Button title="Back to homepage" href="/" />
                        </div>
                    </div>
                </section>
                </>
                
            )
        
        }
        
    }

    return <>
        {
            renderStatus(otpStatus)
        }
        
    </>;
}